import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import rgba from "polished/lib/color/rgba";
import { useApplication } from "@ryerson/frontend.application";
import { useStaticQuery, graphql } from "gatsby";
import { NodeLocale } from "@components/Shared/model/LanguageContent";

export type HeroAlertProps = {
	showAlert: boolean;
	lineOne: string;
	lineTwoLarge: string;
	lineTwoSmall: string;
	lineThree: string;
	lineFour: string;
};

type ContentfulNode = {
	contentful_id: string;
	company: string;
	startDate: string;
	endDate: string;
	lineOneText: string;
	lineTwoLargeText: string;
	lineTwoSmallText: string;
	lineThree: string;
	lineFour: string;
	node_locale: NodeLocale;
};

const AlertContainer = styled.div`
	position: absolute;
	top: 35px;
	height: 280px;
	width: 280px;
	border-radius: 50%;
	z-index: 1;

	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.primaryBrand};

			@media only screen and (min-width: ${theme.breakpoints.md}) and (max-width: ${theme
					.breakpoints.xl}) {
				right: 5%;
			}
			@media only screen and (min-width: ${theme.breakpoints.xl}) {
				right: 10%;
			}
			@media only screen and (min-width: ${theme.breakpoints.xxl}) {
				right: 19%;
			}
		`;
	}}
`;

const TextContainer = styled.div`
	box-sizing: border-box;
	max-width: 185px;
	margin: 0 auto;
	margin-top: 55px;
`;

const Divider = styled.div`
	margin-top: 17px;
	margin-bottom: 17px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${rgba(theme.colors.primary.white, 0.2)};
		`;
	}}
`;

const AlertMobileContainer = styled.div`
	margin-top: 80px;
	margin-right: -20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.primaryBrand};
		`;
	}}
`;

const HeroAlert: React.FC = ({}) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const [alertShowing, setAlertShowing] = React.useState(false);

	const allHeroAlerts = useStaticQuery(graphql`
		query AllHeroAlerts {
			allContentfulHomepageHeroAlert {
				edges {
					node {
						contentful_id
						company
						startDate
						endDate
						lineOneText
						lineTwoLargeText
						lineTwoSmallText
						lineThree
						lineFour
						node_locale
					}
				}
			}
		}
	`);
	const company = process.env.GATSBY_COMPANY === "southernToolSteel" ? "STS" : "Ryerson";
	let ValidAlert = {} as ContentfulNode;
	allHeroAlerts.allContentfulHomepageHeroAlert.edges.forEach((node: any) => {
		let currentDate = new Date();
		let timezoneDifference = currentDate.getTimezoneOffset();
		let start = new Date(node.node.startDate);
		let end = new Date(node.node.endDate);
		let startDate = new Date(start.getTime() + timezoneDifference * 60 * 1000);
		let endDate = new Date(end.getTime() + timezoneDifference * 60 * 1000);
		if (
			currentDate >= startDate &&
			currentDate <= endDate &&
			node.node.node_locale.split("-").shift() === language &&
			node.node.company === company
		) {
			ValidAlert = node.node;
		}
	});

	React.useEffect(() => {
		if (Object.keys(ValidAlert).length) {
			setAlertShowing(true);
		}
	}, []);

	return alertShowing ? (
		<>
			<Media greaterThanOrEqual="lg">
				<AlertContainer theme={theme}>
					<TextContainer>
						<Typography
							type="tertiary"
							color={theme.colors.primary.white}
							variant="p"
							size="sm"
							css={css`
								padding: 0 10px;
							`}
						>
							{ValidAlert.lineOneText}
						</Typography>
						<Typography
							type="tertiary"
							color={theme.colors.primary.white}
							variant="h1"
							css={css`
								display: inline-block;
								font-size: 75px;
							`}
						>
							{ValidAlert.lineTwoLargeText}
							<Typography
								type="tertiary"
								color={theme.colors.primary.white}
								variant="h2"
								css={css`
									display: inline-block;
									vertical-align: super;
								`}
							>
								{ValidAlert.lineTwoSmallText}
							</Typography>
						</Typography>
						<Divider theme={theme} />
						<Typography
							type="tertiary"
							color={theme.colors.primary.white}
							variant="p"
							size="sm"
							css={css`
								margin: 0;
								line-height: 16px;
								padding: 0 10px;
							`}
						>
							{ValidAlert.lineThree}
						</Typography>
						<Typography
							type="tertiary"
							color={theme.colors.primary.white}
							variant="p"
							size="sm"
							css={css`
								margin: 0;
								line-height: 16px;
								padding: 0 10px;
							`}
						>
							{ValidAlert.lineFour}
						</Typography>
					</TextContainer>
				</AlertContainer>
			</Media>
			<Media lessThan="lg">
				<AlertMobileContainer theme={theme}>
					<Flex alignContent="space-between">
						<FlexItem
							css={css`
								width: 50%;
								margin-left: 18px;
								padding-top: 17px;
								padding-bottom: 17px;
							`}
						>
							<Typography
								type="tertiary"
								color={theme.colors.primary.white}
								variant="p"
								size="md"
								css={css`
									margin: 0px;
									line-height: 20px !important;
								`}
							>
								{ValidAlert.lineOneText}
							</Typography>
							<Typography
								type="tertiary"
								color={theme.colors.primary.white}
								variant="h1"
								css={css`
									line-height: 40px !important;
									font-size: 36px !important;
								`}
							>
								{ValidAlert.lineTwoLargeText} {ValidAlert.lineTwoSmallText}
							</Typography>
						</FlexItem>
						<FlexItem
							css={css`
								border-right: 1px solid ${rgba(theme.colors.primary.white, 0.2)};
								margin: 0 20px;
								margin-top: 17px;
								margin-bottom: 17px;
							`}
						></FlexItem>
						<FlexItem
							css={css`
								width: 50%;
								margin-right: 20px;
								padding-top: 17px;
								padding-bottom: 17px;
							`}
						>
							<Typography
								type="tertiary"
								color={theme.colors.primary.white}
								variant="p"
								size="md"
								css={css`
									margin: 0px;
								`}
							>
								{ValidAlert.lineThree} {ValidAlert.lineFour}
							</Typography>
						</FlexItem>
					</Flex>
				</AlertMobileContainer>
			</Media>
		</>
	) : (
		<></>
	);
};

export default HeroAlert;
